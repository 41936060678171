import {
  Body,
  BodyMedium16Css,
  BodyMedium24Css,
  H2,
  H5,
  H5Css,
  H9,
  Heading,
} from "@/components/Typography";
import reviewBackgroundIcon from "../../public/images/homepage/review-background-icon.png";
import { css } from "@emotion/react";
import Button from "@/components/Buttons/Button";
import Image from "next/image";
import { GetStaticProps } from "next";
import Layout from "@/components/Layout/Layout";
import Link from "next/link";
// import { useEffect, useState } from "react";
// import Script from "next/script";
// import PlayButton from "@/svg/PlayButton";
import {
  darkColor,
  lightBlueColor,
  mutedBlueColor,
  textColor,
} from "@/constants/colors";
import { homepageFeatures1, homepageFeatures2 } from "@/constants/homepageData";
import HomepageFeatureCard from "@/components/Homepage/HomepageFeatureCard";
import navbarData from "@/constants/navbarData";
import Sport from "@/components/Homepage/Sport";
import ReviewSiteButton from "@/components/ReviewSiteButton/ReviewSiteButton";
import Balancer from "react-wrap-balancer";
import { demoPath } from "@/constants/paths";
import { mq } from "@/constants/breakpoints";
import HomepageHeroJoin from "@/components/Homepage/HomepageHeroJoin";
import Container from "@/components/Container/Container";
import Testimonials from "@/components/Testimonial/Testimonials";
import BottomCTA from "@/components/BottomCTA/BottomCTA";
import GetStartedButton from "@/components/Buttons/GetStartedButton";
import { getHomepage } from "@/utils/getHomepage";
import trophyIllustration from "../../public/images/homepage/trophy-illustration.svg";

// const WISTIA_ID = "ylz2kajhot";

const Page = ({ fields }: any) => {
  // const [video, setVideo] = useState<any>(null);

  // useEffect(() => {
  //   // @ts-ignore
  //   window._wq = window._wq || [];
  //   // @ts-ignore
  //   window._wq.push({
  //     id: WISTIA_ID,
  //     onReady: function (returnVideo: any) {
  //       setVideo(returnVideo);
  //     },
  //   });
  // }, []);
  // HOOKS END

  // const handleClick = () => {
  //   try {
  //     video.popover.show();
  //     video.play();
  //     // const event = 'Played Explainer Video';
  //     // window.heap?.track(event);
  //   } catch (e) {
  //     console.log(e);
  //     // Sentry.captureException(e);
  //   }
  // };

  return (
    <>
      {/* <Script
        src="https://fast.wistia.com/assets/external/E-v1.js"
        strategy="lazyOnload"
      /> */}
      <Layout
        title={fields.pageTitle}
        metaDescription={fields.metaDescription}
        heroContainerCss={css`
          background-image: url("/images/homepage/BG.svg"),
            linear-gradient(112.24deg, #048cdd 4.51%, #32b2ff 100.81%);
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          z-index: 1;
        `}
        hero={
          <Container
            css={css`
              position: relative;
              z-index: 1;
            `}
          >
            <div
              css={css`
                text-align: center;
                margin-top: 22px;
                color: #fff;
                ${mq["lg"]} {
                  margin-top: 50px;
                }
              `}
            >
              <Heading
                level={1}
                css={css`
                  max-width: 1200px;
                  margin: auto;
                  font-weight: 400;
                  font-size: 28px;
                  line-height: 130%;
                  ${mq["lg"]} {
                    font-size: 56px;
                    line-height: 120%;
                  }
                `}
              >
                <Balancer
                  dangerouslySetInnerHTML={{
                    __html: fields.heroHeading.replace(/\. /, ".<br>"),
                  }}
                ></Balancer>
              </Heading>
              <Body
                css={css`
                  max-width: 925px;
                  margin: 14px auto 0;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 140%;
                  ${mq["lg"]} {
                    margin-top: 24px;
                    font-weight: 500;
                    font-size: 20px;
                  }
                `}
              >
                <Balancer>{fields.heroSubheading}</Balancer>
              </Body>
              <div
                css={css`
                  margin-top: 24px;
                  ${mq["lg"]} {
                    margin-top: 40px;
                  }
                `}
              >
                <HomepageHeroJoin />
              </div>
              <div
                css={css`
                  margin-top: 28px;
                  display: flex;
                  flex-direction: column;
                  gap: 24px;
                  align-items: center;
                  justify-content: center;
                  ${mq["sm"]} {
                    flex-direction: row;
                  }
                  ${mq["lg"]} {
                    margin-top: 41px;
                  }
                `}
              >
                <GetStartedButton
                  btnCss={css`
                    min-width: 177px;
                    height: 50px;
                  `}
                />
                <Link href={demoPath}>
                  <Button jwType="lightOutline">Watch Demo</Button>
                </Link>
              </div>
              <div
                css={css`
                  margin-top: 40px;
                  position: relative;
                  ${mq["lg"]} {
                    margin-top: 60px;
                  }
                `}
              >
                <Image
                  priority
                  /* onClick={handleClick} */
                  src="/images/homepage/hero.webp"
                  width="989"
                  height="361"
                  alt="Homepage Hero Image"
                  css={css`
                    max-width: 100%;
                    height: auto;
                    margin-bottom: -4px;
                  `}
                />
                {/* video && (
                  <PlayButton
                    onClick={handleClick}
                    role="button"
                    css={css`
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                      margin: auto;
                    `}
                  />
                )*/}
              </div>
            </div>
          </Container>
        }
      >
        <div
          css={css`
            background-color: ${mutedBlueColor};
          `}
        >
          <Container
            css={css`
              text-align: center;
              position: relative;
              padding-top: 60px;
              padding-bottom: 206px;
              ${mq["sm"]} {
                text-align: left;
                padding-top: 40px;
                padding-bottom: 40px;
              }
              ${mq["lg"]} {
                padding-top: 75px;
                padding-bottom: 113px;
              }
            `}
          >
            <div
              css={css`
                ${mq["sm"]} {
                  max-width: 350px;
                }
                ${mq["md"]} {
                  max-width: 510px;
                }
                ${mq["lg"]} {
                  max-width: 584px;
                }
              `}
            >
              <Heading
                level={3}
                css={css`
                  color: ${darkColor};
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 140%;
                  ${mq["lg"]} {
                    font-size: 24px;
                  }
                `}
              >
                <Balancer>
                  The all-in-one platform to take your sports organization
                </Balancer>
              </Heading>
              <Heading
                level={3}
                css={css`
                  margin-top: 8px;
                  font-weight: 400;
                  font-size: 28px;
                  color: ${darkColor};
                  ${mq["lg"]} {
                    font-size: 56px;
                  }
                `}
              >
                to the{" "}
                <span
                  css={css`
                    color: ${lightBlueColor};
                  `}
                >
                  next level.
                </span>
              </Heading>
              <H9
                css={css`
                  margin-top: 24px;
                  color: ${textColor};
                  ${mq["lg"]} {
                    font-weight: 350;
                    font-size: 18px;
                  }
                `}
              >
                <Balancer>
                  Build a website, manage registration, send event
                  notifications, and more!
                </Balancer>
              </H9>
            </div>
            <Image
              src={trophyIllustration}
              alt="Trophy Illustration"
              css={css`
                position: absolute;
                z-index: 0;
                bottom: -20px;
                margin: auto;
                right: 0;
                left: 0;
                height: 244px;
                width: auto;
                ${mq["sm"]} {
                  top: 0;
                  left: unset;
                  height: 317px;
                  bottom: 0;
                  width: auto;
                  margin: auto 0;
                }
                ${mq["md"]} {
                  height: 248px;
                }
                ${mq["lg"]} {
                  height: 568px;
                }
              `}
            />
          </Container>
        </div>
        <div
          css={css`
            background-color: #fff;
            position: relative;
          `}
        >
          <Container>
            {homepageFeatures1.map(
              ({ title, description, tag, href, imageSrc }, index) => {
                return (
                  <HomepageFeatureCard
                    index={index}
                    title={title}
                    description={description}
                    imageSrcMobile={imageSrc}
                    tag={tag}
                    href={href}
                    key={index}
                  />
                );
              }
            )}
            <H5
              css={css`
                color: ${darkColor};
                padding: 60px 0 0;
              `}
            >
              And more...
            </H5>
            <div
              css={css`
                ${mq["lg"]} {
                  display: flex;
                  gap: 40px;
                }
              `}
            >
              {homepageFeatures2.map(
                (
                  { title, description, imageSrcDesktop, imageSrcMobile, href },
                  index
                ) => (
                  <HomepageFeatureCard
                    index={index}
                    key={index}
                    title={title}
                    description={description}
                    imageSrcDesktop={imageSrcDesktop}
                    imageSrcMobile={imageSrcMobile}
                    href={href}
                  />
                )
              )}
            </div>
          </Container>
          <div
            css={css`
              padding-top: 60px;
            `}
          />
        </div>
        <div
          css={css`
            padding: 50px 0 50px;
            background: url(/images/homepage/sports-bg.svg),
              linear-gradient(112.24deg, #048cdd 4.51%, #32b2ff 100.81%);
            background-size: cover;
            background-repeat: no-repeat;
            ${mq["lg"]} {
              padding: 100px 0 100px;
            }
          `}
        >
          <Container>
            <Heading
              level={3}
              css={css`
                color: #fff;
                text-align: center;
                padding-bottom: 50px;
                ${H5Css}
                ${mq["lg"]} {
                  padding-bottom: 80px;
                  font-size: 50px;
                }
              `}
            >
              Made to help your sports organization grow!
            </Heading>
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
                ${mq["sm"]} {
                  grid-template-columns: repeat(3, 1fr);
                }
                ${mq["lg"]} {
                  grid-template-columns: repeat(4, 1fr);
                }
              `}
            >
              {navbarData.sports.map(({ title, href, Icon }, index) => (
                <Sport key={index} title={title} href={href} Icon={Icon} />
              ))}
            </div>
          </Container>
        </div>
        <Testimonials testimonials={fields.testimonialsCollection.items} />
        <Container
          css={css`
            padding: 60px 0;
            display: flex;
            flex-direction: column;
            gap: 60px;
            ${mq["md"]} {
              flex-direction: row;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={css`
              position: relative;
              text-align: center;
              ${mq["md"]} {
                text-align: left;
              }
            `}
          >
            <Image
              src={reviewBackgroundIcon}
              alt="Review background icon"
              css={css`
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                width: 218px;
                height: auto;
                ${mq["md"]} {
                  right: -150px;
                  width: 242px;
                }
                ${mq["lg"]} {
                  width: 377px;
                }
              `}
            />
            <H2
              css={css`
                color: ${lightBlueColor};
                padding-top: 40px;
                ${mq["lg"]} {
                  font-weight: 400;
                  font-size: 90px;
                  line-height: 130.02%;
                }
              `}
            >
              {process.env.organizationCount}+
            </H2>
            <Body
              css={css`
                color: ${textColor};
                ${BodyMedium16Css}
                ${mq["lg"]} {
                  ${BodyMedium24Css}
                }
              `}
            >
              <Balancer>
                sports organizations are using Jersey Watch this season
              </Balancer>
            </Body>
          </div>
          <div
            css={css`
              margin: auto;
              display: flex;
              flex-direction: column;
              gap: 15px;
              max-width: 100%;
              ${mq["md"]} {
                margin: unset;
              }
            `}
          >
            <ReviewSiteButton type="g2" />
            <ReviewSiteButton type="trustpilot" />
          </div>
        </Container>
        <BottomCTA />
      </Layout>
      {/* <div
        className={`wistia_embed wistia_async_${WISTIA_ID} popover=true stillUrl=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`}
        style={{ position: "relative", width: 0, height: 0 }}
      /> */}
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const { fields } = await getHomepage();
  return {
    props: { fields }, // will be passed to the page component as props
  };
};

export default Page;
